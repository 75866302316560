<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="600">
      <v-card>
        <v-card-title>
          KPI's
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>

        <v-card-text class="pa-0">

          <v-list>
            <template v-for="(item, index) in KPIs">
              <v-list-item :key="`list-item-${index}-${item.id}`" link>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-list-item-action-text>
                    <v-chip color="success" dark small>{{ item.pivot.winning_points | toCurrency }}</v-chip>
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>

              <v-divider v-if="index < KPIs.length - 1" :key="`divider-${index}-${item.id}`"></v-divider>
            </template>
          </v-list>

        </v-card-text>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>


export default {
   data() {
      return {
         dialog: false,
         KPIs: [],
      }
   },
   methods: {
      open(KPIs) {
         this.dialog = true
         this.KPIs = KPIs
      },
      close() {
         this.dialog = false
      }
   },
}
</script>

<style scoped>

</style>