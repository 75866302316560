<template>
  <div>
    <v-dialog v-model="dialog"
              v-if="data"
              width="400"
              persistent
              scrollable>
      <v-card :loading="btnLoading" :disabled="btnLoading">

        <v-card-title>
          Prolonger la date de fin
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4">

          <v-menu ref="menu"
                  v-model="startDateMenu"
                  :close-on-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :value="form.start_date"
                            :disabled="data.remaining_days?.color !== 'warning'"
                            @input="errors && errors.start_date ? errors.start_date = '' : false"
                            :error-messages="errors.start_date"
                            label="Date de début *"
                            append-icon="mdi-calendar" readonly
                            v-bind="attrs" v-on="on">
              </v-text-field>
            </template>
            <v-date-picker
                :min="dateNow"
                v-model="form.start_date" scrollable>
            </v-date-picker>
          </v-menu>

          <v-menu ref="menu"
                  v-model="endDateMenu"
                  :close-on-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :value="form.end_date"
                            @input="errors && errors.end_date ? errors.end_date = '' : false"
                            :error-messages="errors.end_date"
                            label="Date de fin *"
                            append-icon="mdi-calendar" readonly
                            v-bind="attrs" v-on="on">
              </v-text-field>
            </template>
            <v-date-picker
                :min="data.remaining_days?.color === 'error' ? dateNow : form.start_date"
                v-model="form.end_date" scrollable>
            </v-date-picker>
          </v-menu>

        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary"
                 :loading="btnLoading"
                 @click="save"
                 depressed>
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      dateNow: this.$moment().format('YYYY-MM-DD'),
      btnLoading: false,
      dialog: false,
      startDateMenu: false,
      endDateMenu: false,
      form: {
        raffle_campaign_id : null,
        start_date : null,
        end_date : null,
      },
      errors : {},
      data : null,
    }
  },
  methods: {
    open(item) {
      this.data = item
      this.form.raffle_campaign_id = item.id
      this.form.start_date = item.start_date
      this.form.end_date = item.end_date
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    save() {
      this.btnLoading = true
      this.$Progress.start()
      HTTP.post('/raffle-campaigns/prolong-end-date', this.form).then(() => {
        this.btnLoading = false
        this.$Progress.finish()
        this.close()
        this.$emit('refresh')
        this.$successMessage = 'Cette campagne a été modifier avec succès'
      }).catch(err => {
        this.$Progress.fail()
        this.btnLoading = false
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
        console.log(err)
      })
    }
  },
}
</script>

<style scoped>

</style>