<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="300">
      <v-card>
        <v-card-title>
          Superviseurs
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>

        <v-card-text class="pa-0">


          <v-simple-table>
            <template v-slot:default>
              <tbody>

              <tr v-for="(supervisor, index) in statementCampaign.supervisors" :key="index">

                <td>
                  <div class="d-flex align-center py-4">
                    <v-avatar size="35">
                      <v-img v-if="supervisor.photo"
                             :src="FILE_URL + supervisor.photo"></v-img>
                      <v-img v-else :src="require('@/assets/avatar2.png')"></v-img>
                    </v-avatar>
                    <div>
                      <span class="ml-2 text-no-wrap">{{ supervisor.name }}</span>
                    </div>
                  </div>
                </td>

              </tr>
              </tbody>
            </template>
          </v-simple-table>


        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      FILE_URL: process.env.VUE_APP_FILE_URL,
      statementCampaign: {},
    }
  },
  methods: {
    open(statementCampaign) {
      this.dialog = true
      this.statementCampaign = statementCampaign
    },
    close() {
      this.dialog = false
    }
  },
}
</script>

<style scoped>
.theme--dark .grey--text {
  color: #6f6c80 !important;
  caret-color: #6f6c80 !important;
}
</style>