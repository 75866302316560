<template>
  <div>
    <div class="pa-4">

      <v-menu ref="menu"
              v-model="menu"
              :close-on-click="false"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto">
        <template v-slot:activator="{ on, attrs }">

          <v-text-field :value="dates.join(' ~ ')"
                        clearable
                        dense
                        filled
                        prepend-inner-icon="mdi-calendar-outline"
                        label="Date de planification"
                        hide-details
                        rounded
                        @click:clear="dates = []"
                        readonly
                        v-bind="attrs" v-on="on"
                        class="rounded mb-2"/>

        </template>
        <v-date-picker
            v-model="dates" range scrollable>
          <v-spacer></v-spacer>
          <v-btn text class="rounded-lg"
                 @click="menu = false">
            <v-icon left>mdi-close</v-icon>
            Fermer
          </v-btn>

          <v-btn text color="primary"
                 :disabled="dates.length < 2"
                 class="rounded-lg"
                 @click="menu = false">
            <v-icon left>mdi-check</v-icon>
            Ok
          </v-btn>
        </v-date-picker>
      </v-menu>

      <v-btn block
             color="primary"
             depressed
             class="rounded-lg"
             large @click="onFilter">
        Filtrer
      </v-btn>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dates: [],
      filter: {},
      menu: false,
    }
  },
  methods: {
    onFilter() {
      let filter = {
        start_date: this.dates.length === 2 ? this.dates[0] : null,
        end_date: this.dates.length === 2 ? this.dates[1] : null,
      }
      this.$emit('filter', filter)
    },
  }
}
</script>

<style scoped>

</style>